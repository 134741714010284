@import '/styles/vars';

.heading {
    text-align: center;
}

.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: $dashed;
    padding-bottom: 24px;
}

.icon {
    width: 48px;
    height: 48px;
    fill: $color_dark;
    margin: 0 0 12px 0;
    transition: fill $ease;
}

.feature {
    width: 12.5%; // 16.6%; //12.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 1.2;
    font-size: $font14;
    position: relative;
    margin: 0 0 24px 0;
    padding: 0 12px;
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &:hover {
        svg {
            fill: $color_green;
        }
    }
    @media (max-width: 1000px) {
        .icon {
            width: 36px;
            height: 36px;
        }
        width: 16.6%;
    }
    @include mobile {
        width: 25%;
        padding: 0 6px;
        font-size: 12px;
    }
}

.bottom {
    width: 100%;
    text-align: center;
}

.virtual {
    pointer-events: none;
    opacity: 0;
}

.name {
    height: 2.4em;
}

.more {
    cursor: pointer;
    color: $color_green;
    display: inline-block;
    &:hover {
        text-decoration: underline;
    }
}

.hidden {
    .more8 {
        display: none;
    }
    @media (max-width: 1000px) {
        .more6 {
            display: none;
        }
    }
    @include mobile {
        .more4 {
            display: none;
        }
    }
}

.all {
    border: 0;
}

.paveldas {
    transform: scale(0.95);
}

.lyciulygybe,
.inovacija {
    transform: scale(1.1);
}

.saugantgyvunus {
    transform: scale(1.3);
}

.tvaripakuote,
.bioivairovestausojimas,
.vietiniuzaliavu {
    transform: scale(1.2);
}

.rankudarbo {
    transform: scale(1.05);
}

.idarbinantvietinesbendruomenes {
    transform: scale(1.3);
}

.naturalus,
.ekologiskas,
.idarbinantsocialiaijautriasvisuomenesgrupes {
    transform: scale(1.05);
}

.christmasIcon:nth-child(even) svg {
    fill: $color_green;
}

.christmasIcon:nth-child(odd) svg {
    fill: $color_green;
}
