@import '/styles/vars';

html {
    .like {
        position: absolute;
        right: $gap12;
        top: $gap12;
        background: #fff;
        z-index: 1;
        border-radius: 50%;
        transform: translate3d(0, 6px, 0);
        opacity: 0;
        width: 42px;
        height: 42px;
        padding: 0;
        transition: transform $ease, opacity $ease, color $ease;
        svg {
            transition: transform $ease;
            top: 1px;
            position: relative;
        }
        &:hover {
            color: $color_red;
            background: #fff;
            svg {
                transform: scale(1.1);
            }
        }
        @include mobile {
            transform: none;
            padding: 0;
            right: 9px;
            top: 9px;
            opacity: 1;
            width: 30px;
            height: 30px;
            svg {
                stroke-width: 2;
                padding: 4px;
            }
        }
    }

    .active {
        color: $color_red;
        transform: translate3d(0, 0, 0);
        opacity: 1;
        svg {
            fill: $color_red;
        }
    }

    .container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        &:hover {
            .like {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }

    .visible {
        pointer-events: none;
        .like {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            pointer-events: all;
        }
    }
}
