@import '/styles/vars';

.scroll {
    @include mobile {
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0 -9px;
        -webkit-overflow-scrolling: touch;
    }
}

.container {
    margin: 0 -12px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 60px;
    @include mobile {
        width: 185%;
        margin: 0;
        padding-bottom: 60px;
    }
}

// .posts {
//     display: flex;
//     margin: 0 -12px;
// }

.card {
    padding: 0 12px;
    width: 25%;
    margin-bottom: 24px;
    display: flex;
    > div {
        // border-radius: $radiusHero;//$radiusImg;
        // background: #fff;//$color-light;
        padding-bottom: 9px;
        width: 100%;
        display: flex;
        // overflow: hidden;
        // position: relative;
        flex-direction: column;
        > div:first-child {
            border-radius: $radiusHero;
            overflow: hidden;
            position: relative;
            margin-bottom: 12px;
            > div {
                transition: transform 200ms ease-in-out;
                transform: scale(1);
            }
        }
    }
    &:hover {
        > div {
            > div:first-child > div {
                transform: scale(1.1);
                
            } 
        }
    }
    h3 {
        // font-weight: normal;
        font-size: 21px;//17px;
        font-weight: 700;
        flex-grow: 1;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 9px 0px 9px;
        font-family: $bodyFont;
        font-weight: 400;
        margin: 0;
        // border-top: $border;
        // line-height: 1.33;
        // font-weight: $bold !important;
        text-align: center;
        // @include mobile {
        //     font-size: $font16;
        // }
    }
    @include mobile {
        padding: 0 9px;
        width: 25%;
        margin-bottom: 18px;
        h3 {
            font-size: 19px;//14px;
        }
    }
}

.image {
    // border-radius: $radiusImg;
    // background: $color-light;
    position: relative;
    overflow: hidden;
    // margin-bottom: 12px;
    // padding-bottom: 66%;
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    img {
        mix-blend-mode: multiply;
    }
    @include mobile {
        margin-bottom: 9px;
    }
}
