@import '/styles/vars';

.container {
    @include mobile {
        width: 100vw;
        left: 50%;
        position: relative;
        margin-left: -50vw;
        overflow: hidden;
        padding: 0 $gapM;
    }
}

.moreSlide {
    padding: 0 9px;
}

.more {
    padding-bottom: 100%;
    background: $color-light;
    border-radius: $radius2;
    position: relative;
    > a {
        font-weight: 500;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.slider {
    display: flex;
    margin: -5px 0 -5px -9px;
    overflow: auto;
    padding: 5px 0;
    -webkit-overflow-scrolling: touch;
    //@include mobile {
    //overflow: visible;
    //margin: 0 calc(8vw - 9px) 0 -9px;
    //}
    @include mobile {
        margin-right: -40vw;
        &:after {
            content: '';
            width: calc(40vw - 9px);
            height: 10px;
            display: block;
            flex-shrink: 0;
        }
    }
    @media (max-width: 1189px) {
    }
    @media (min-width: 900px) {
        margin: 0 -12px;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > * {
        margin: 0;
    }
    > *:not(:first-child) > *:not(:first-child) {
        margin-left: $gap12;
    }
}

.slide {
    width: 33.33%;
    flex-shrink: 0;
}

.virtual {
    .top {
        min-height: 48px;
    }
}

@media (min-width: 600px) {
    .slide {
        width: 33.33%;
    }
}

@media (min-width: 700px) {
    .slide {
        width: 20%; //33.33%;
    }
    .alt {
        width: 29%;
    }
}

@media (min-width: 900px) {
    .slide {
        width: 25%;
    }
    .alt {
        width: 50%;
    }
}

@media (min-width: 1080px) {
    .slide {
        width: 20%;
    }
    .alt {
        width: 33.33%;
    }
}

@media (min-width: 1190px) {
    .slide {
        width: 20%;
    }
    .alt {
        width: 25%;
    }
}

/*
width: 20%;
flex-shrink: 0;


600: {
    slidesPerView: 3,
    slidesPerGroup: 3
},
750: {
    slidesPerView: withSidebar ? 2 : 3,
    slidesPerGroup: withSidebar ? 2 : 3
},
900: {
    slidesPerView: withSidebar ? 2 : 4,
    slidesPerGroup: withSidebar ? 2 : 4
},
1080: {
    slidesPerView: withSidebar ? 3 : 5,
    slidesPerGroup: withSidebar ? 3 : 5
},
1190: {
    slidesPerView: withSidebar ? 4 : 5,
    slidesPerGroup: withSidebar ? 4 : 5
}
*/

/*


.slider {
    margin: 0 -18px;
    @include mobile {
        overflow: visible;
        margin: 0 calc(8vw - 9px) 0 -9px;
    }
}


*/
