@import '/styles/vars';

.rating {
    display: flex;
    align-items: center;
    > * {
        width: auto !important;
    }
}

.count {
    color: $color_faded;
    font-size: $font13;
    margin-left: 6px;
    position: relative;
}
