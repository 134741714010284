@import '/styles/vars';

.card {
    padding: 0 $gap12;
    h3 {
        margin: 0 0 -2px 0;
    }
    @include mobile {
        padding: 0 9px;
    }
}

.img {
    font-size: 0;
    margin: 0 0 $gap12 0;

    background: $color_light;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    border-radius: $radiusImg;
    position: relative;
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    img {
        mix-blend-mode: multiply;
        width: 100%;
        height: auto;
    }
}

.brand {
    color: $color_faded;
    font-size: $font14;
    margin-top: 6px;
    display: inline-block;
    @include mobile {
        font-size: 12px;
    }
}

.price {
    // font-weight: $bold;
    // font-size: $font16;
    // color: $color_green;
    font-size: $font14;
    margin: 0 0 3px 0;
    display: flex;
    align-items: center;
    @include mobile {
        font-size: $font14;
    }
}

.virtualImage {
    opacity: 0;
    > div {
        padding-bottom: 100%;
    }
}
