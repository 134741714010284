@import '/styles/vars';

.flag {
    width: 35px;
    height: 24px;
    display: inline-block;
    margin: 0 0 0 18px;
    @include mobile {
        margin: 0 0 0 6px;
        width: 26px;
        height: 18px;
    }
}

.heading {
    white-space: nowrap;
    padding-top: 3px;
    @include mobile {
        + * {
            display: none;
        }
    }
}

// .egg {
//     perspective: 750px;
//     transform: translate3d(0, 0, 0) scale(1.8);
//     transform-style: preserve-3d;
//     margin: 0 0 0 30px;
//     position: relative;
//     top: 6px;
//     svg {
//         left: 0em;
//         position: absolute;
//         transform-origin: 50% 0.5em;
//         top: 0.1em;
//         // transform: scale(1.4);
//         // animation: eggRotate 3s infinite cubic-bezier(0.5, 0.15, 0.5, 0.85), eggTop 3s infinite;
//     }
//     @include mobile {
//         transform: translate3d(0, 0, 0) scale(1.5);
//         top: 0;
//         margin: 0 0 0 18px;
//     }
// }

@keyframes eggRotate {
    to {
        transform: rotateZ(360deg);
    }
}

@keyframes eggTop {
    0%,
    100% {
        top: 0em;
        animation-timing-function: cubic-bezier(0.75, 0, 0.5, 0.5);
    }
    35%,
    65% {
        top: 0.2em;
        animation-timing-function: cubic-bezier(0.5, 0.5, 0.25, 1);
    }
}
