@import '/styles/vars';

.stars {
    font-size: 0;
    //text-align: center;
    margin: 0 0 0 -4px;
    position: relative;
    svg {
        padding: 4px;
        stroke-width: 2;
    }
    > * {
        width: 20px;
        //padding: 0 3px;
        //width: $gap24 + 6px;
        color: $color_accent;
    }
}

.active {
    svg {
        fill: $color_accent;
    }
}
