@import '/styles/vars';

.card {
    padding: 0 $gap12;
    @include mobile {
        padding: 0 9px;
    }
}

.wrap {
    position: relative;
}

.img {
    background: $color_light;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    border-radius: $radius3;
    position: relative;
    padding-bottom: 100%;
    margin: 0 0 18px 0;
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.logo {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 25%;
    top: 25%;
    img {
        mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        width: auto;
        height: auto;
    }
}

.description {
    //color: $color_faded;
    opacity: 0.6s;
    font-size: $font14;
    line-height: 1.25;
}

.discount {
    position: absolute;
    background: $color_red;
    color: #fff;
    font-weight: $bold;
    padding: 6px 12px 6px 0;
    top: 12px;
    right: -12px;
    text-align: center;
    pointer-events: none;
    width: 84px;
    &:before {
        content: '';
        position: absolute;
        top: 100%;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 12px 0 0;
        opacity: 0.5;
        border-color: $color_red transparent transparent transparent;
    }
}

.name {
    margin: 0 0 6px 0;
}
