@import '/styles/vars';

.badges {
    display: flex;
}

.ukraina {
    align-items: center;
    display: flex;
    margin-left: 9px;
    img {
        width: 24px !important;
        height: auto;
    }
}

.badge {
    color: #fff;
    display: flex;
    align-items: center;
    height: 21px;
    padding: 0 9px;
    border-radius: $radius1;
    margin-left: 9px;
    font-size: $font13;
    position: relative;
    top: -1px;
    svg {
        width: 14px;
        height: 14px;
    }
}

.yellow {
    background: $color_yellow_light;
    color: $color_yellow;
}

.green {
    background: $color_green_light;
    color: $color_green_text;
}

.red {
    background: $color_red_light;
    color: $color_red_text;
}
