@import '/styles/vars';

.top {
    width: 100vw;
    position: absolute;
    bottom: 100%;
    height: 4.484vw;
    margin-top: -6px;
    left: 50%;
    margin-left: -50vw;
    fill: $color_light;
}

.bottom {
    fill: $color_light;
    width: 100vw;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -50vw;
    height: 5.415vw;
    margin-top: -6px;
}

.sss {
    position: relative;
    padding-top: 60px;
    padding-bottom: 1px;
}

.rrr {
    padding-bottom: 1px;
}

.ttt {
    margin-top: -60px;
    margin-bottom: 5.415vw;
    position: relative; 
    .bbb {
        background: $color_light;
        position: absolute;
        width: 100vw;
        position: absolute;
        left: 50%;
        height: 100%;
        margin-left: -50vw;
    }
}

.topw {
    position: relative;
    // padding-top: 4.484vw;
    margin-top: 180px;
}

.hero {
    margin-top: -60px;
    background: $color_light;
    > * {
        position: relative;
    }
}

.content {
    height: 44.1vw; //635px;
    max-height: 635px;
    @include mobile {
        height: calc(80vw + 114px + 48px);
        max-height: 100%;
    }
}

.paper1 {
    position: absolute;
    width: 1440px;
    height: 64px;
    left: 100%;
    fill: #fff;
    margin-left: -217px;
    z-index: 0;
    bottom: 0;
}

.paper2 {
    position: absolute;
    width: 1440px;
    height: 64px;
    right: 100%;
    fill: #fff;
    margin-right: -7px;
    z-index: 0;
    bottom: 0;
}

.bg {
    position: relative;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    max-width: 1440px;
}

.mobileImage,
.image {
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @include mobile {
        display: none;
    }
}

.mobileImage {
    display: none;
    @include mobile {
        display: block;
    }
}
