@import '/styles/vars';

.price {
    font-size: $font18;
    b {
        color: $color_red;
    }
}

.oldPrice {
    position: relative;
    top: -0.4em;
    margin-left: 0.5em;
    font-size: 80%; //$font14;
    color: $color_faded;
    text-decoration: line-through;
    font-weight: $normal;
}
